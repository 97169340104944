var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-tabs', {
    attrs: {
      "content-class": "pt-1 w-full",
      "fill": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Belum Setor",
      "active": _vm.stokTab
    }
  }, [_c('ungiven-payment')], 1), _c('b-tab', {
    attrs: {
      "title": "Data Setoran Diterima",
      "active": _vm.pengembalianTab
    }
  }, [_c('data-setoran', {
    attrs: {
      "active": _vm.pengembalianTab
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Sudah Setor",
      "active": _vm.sudahsetorTab
    }
  }, [_c('given-payment', {
    attrs: {
      "active": _vm.sudahsetorTab
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }