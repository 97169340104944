var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('setoran-summarize', {
    attrs: {
      "summarize": _vm.ungivenSummarize
    }
  })], 1)], 1), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.isSales ? _c('b-button', {
    attrs: {
      "variant": _vm.checkedBarangs.length > 0 ? 'outline-primary' : 'outline-secondary',
      "disabled": _vm.checkedBarangs.length < 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmSetor($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CornerUpRightIcon"
    }
  }), _vm._v(" Setor ke Finance "), _vm.checkedBarangs.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.checkedBarangs.length) + ")")]) : _vm._e()], 1) : _vm._e()], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function callback($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.metode != 4 ? _c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.checkedBarangs,
            callback: function callback($$v) {
              _vm.checkedBarangs = $$v;
            },
            expression: "checkedBarangs"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "SlashIcon"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(tgl_pembayaran)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tgl_pembayaran)) + " ")];
      }
    }, {
      key: "cell(sales)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.sales ? _c('strong', {
          staticClass: "text-primary"
        }, [_vm._v(" " + _vm._s(item.sales.nama_lengkap) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Sales tidak ditemukan")])];
      }
    }, {
      key: "cell(metode)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.imetode[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.imetode[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(nominal)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.metode_ket === 'Giro' ? _c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.giro)))]) : _c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.nominal)))])];
      }
    }, {
      key: "cell(stocks)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.getTotal(item.stocks)) + " ")];
      }
    }, {
      key: "cell(jumlah_retur)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.jumlah_retur,
            callback: function callback($$v) {
              _vm.$set(item, "jumlah_retur", $$v);
            },
            expression: "item.jumlah_retur"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('setoran-modal', {
    attrs: {
      "items": _vm.checkedBarangs
    },
    on: {
      "submit": _vm.submit
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }