<template>
  <b-overlay :show="loading">
    <b-row>
        <b-col cols="12">
          <setoran-summarize :summarize="ungivenSummarize" />
        </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-button
          v-if="isSales"
            :variant="checkedBarangs.length > 0 ? 'outline-primary' : 'outline-secondary'"
            @click.prevent="confirmSetor"
            :disabled="checkedBarangs.length < 1"
          >
            <feather-icon icon="CornerUpRightIcon"></feather-icon>
            Setor ke Finance <span v-if="checkedBarangs.length > 0">({{ checkedBarangs.length }})</span>
          </b-button>
        </b-col>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- data -->
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #head(checkbox)>
              <b-form-checkbox  v-model="allChecked"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{ item }">
              <b-form-checkbox v-if="item.metode != 4" v-model="checkedBarangs" :value="item"></b-form-checkbox>
              <feather-icon icon="SlashIcon" class="text-danger" v-else />
            </template>
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(tgl_pembayaran)="{ item }">
            {{humanDate(item.tgl_pembayaran)}}
          </template>
            <template #cell(sales)="{item}">
              <strong class="text-primary" v-if="item.sales">
                {{ item.sales.nama_lengkap }}
              </strong>
              <i class="text-danger" v-else>Sales tidak ditemukan</i>
            </template>
            <template #cell(metode)="data">
              <b-badge :variant="imetode[1][data.value]">
                {{ imetode[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(nominal)="{ item }">
              <strong v-if="item.metode_ket === 'Giro'"
                >Rp
                {{
                  formatRupiah(item.giro)
                }}</strong
              >
              <strong v-else
                >Rp
                {{
                  formatRupiah(item.nominal)
                }}</strong
              >
          </template>
            <template #cell(stocks)="{ item }">
              {{ getTotal(item.stocks) }}
            </template>
            <template #cell(jumlah_retur)="{ item }">
              <b-form-input type="number" v-model="item.jumlah_retur"></b-form-input>
            </template>
          </b-table>
        </b-col>
        <!-- / -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>

      <setoran-modal @submit="submit" :items="checkedBarangs"></setoran-modal>
    </b-card>
  </b-overlay>
</template>
<script>
import SetoranSummarize from './Summarize.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import {
  BRow,
  BCol,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardText,
  BTable,
  BFormCheckbox,
  BTooltip,
  BPagination,
} from "bootstrap-vue";
import SetoranModal from "./Modal.vue";
export default {
  components: {
    SetoranSummarize,
    BOverlay,
    BCard,
    SetoranModal,
    BInputGroupAppend,
    BFormCheckbox,
    BTable,
    BPagination,
    BTooltip,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  BCardText,
  StatisticCardHorizontal,
  },
  watch: {
    allChecked(val) {
      if (val) {
        this.checkedBarangs = [];
        this.items.map((barang) => {
          this.checkedBarangs.push(barang);
        });
      } else {
        this.checkedBarangs = [];
      }
    },
  },
  data() {
    return {
    imetode: [
        {
          1: "Tunai",
          2: "Transfer",
          3: "Giro",
          4: "Nota Putih",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-warning",
          4: "light-danger",
        },
      ],
    loading: false,
    allChecked: false,
    checkedBarangs: [],
    items: [],
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    dash:[],
    fieldsdash: [
      { key: "metode", label: "Metode"},
      { key: "total", label: "Total"},
      { key: "count", label: "Jumlah Data"},
    ],
    perPage2: 10,
    pageOptions2: [10, 30, 50],
    totalRows2: 1,
    currentPage2: 1,
    sortBy2: "",
    sortDesc2: false,
    sortDirection2: "asc",
    filter2: null,
    filterOn2: [],
    fields: [
      { key: "checkbox", label: "No" },
      { key: "no", label: "No" },
      { key: "tgl_pembayaran", label: "Tanggal", sortable: true },
        { key: "metode", label: "Metode", sortable: true },
        { key: "bank", label: "bank", sortable: true },
        { key: "nominal", label: "nominal", sortable: true },
        { key: "keterangan", label: "Keterangan", sortable: true },
    ],
    swalOptions: {
      title: "Setor Pembayaran ke Finance",
      text: `Anda yakin?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
      customClass: {
        confirmButton: "btn btn-outline-success",
        cancelButton: "btn btn-danger ml-1",
      },
      buttonsStyling: false,
    },
  };
},
  computed: {
    ungivenSummarize() {
      return this.$store.getters['belumsetor/getSummarize']
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsdash
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    async getDataDashboard() {
      let params = this.isFinance
        ? { setor: 0, jenis: 1}
        : { setor: 0, jenis: 1 };
      this.loading = true;
        const dashs = await this.$store.dispatch("belumsetor/getDashboard", params);
      this.dash = dashs;
      this.totalRows2 = dashs.length;
      this.loading = false;
    },
    async submit(payload) {
      try {

        this.loading = true;
        await this.$store.dispatch("penjualan/saveSetoran", payload);
        this.loading = false;
        this.checkedBarangs = []
        this.displaySuccess({
          message: 'Pembayaran sudah disetor ke Finance!'
        })
        this.getData()
        this.$bvModal.hide('setoran-modal')
        setTimeout(() => window.location.reload(), 1000)
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    confirmSetor() {
      this.$swal(this.swalOptions).then((res) => {
        if (res.value) {
          this.$store.commit('setoran/SET_CHECKED_BARANG', this.checkedBarangs)
          this.$bvModal.show("setoran-modal");
        }
      });
    },
    getTotal(total) {
      if (!total || total.length < 1) {
        return 0;
      }
      return total.reduce((total, item) => (total += item.nominal), 0);
    },
    async getData() {
      let params = this.isSales
        ? { id_sales: this.user.karyawan.id, order: "desc", disetor: 0 }
        : { order: "desc", disetor: 0 };

      this.loading = true;
      const payments = await this.$store.dispatch("belumsetor/getData", params);
      const summarize = {
        tunai: payments.filter(payment => payment.metode == 1),
        transfer: payments.filter(payment => payment.metode == 2),
        giro: payments.filter(payment => payment.metode == 3),
        nota_putih: payments.filter(payment => payment.metode == 4)
      }
      this.$store.commit('belumsetor/SET_DATA_SUMMARIZE', summarize)
      this.loading = false;
      this.items = payments;
      this.totalRows = payments.length;
    },
    async getBarang() {
      this.barangs = await this.$store.dispatch("barang/getDataV2");
      const barangIds = this.barangs.map((barang) => barang.id);
      this.getStockBarang(barangIds);
    },
    async getStockBarang(barangIds) {
      const params = {
        // barang_id: barangIds.join(','),
        // gudang_id: this.$route.params.id,
        gudang_id: this.myGudang.id,
      };

      const stocks = await this.$store.dispatch("penyimpanan/getData", params);
      this.barangs.map((barang) => {
        barang.stocks = [];
        const findStocks = stocks.filter(
          (stock) => stock.barang && stock.barang.id == barang.id
        );

        if (findStocks && findStocks.length > 0) {
          findStocks.forEach((stock) => {
            barang.stocks.push({
              id: stock.id,
              jumlah: stock.jumlah,
              gudang: stock.gudang,
              blok: stock.blok,
              rak: stock.rak,
              laci: stock.laci,
            });
          });
        }

        barang.jumlah_retur =
          barang.stocks.length > 0 ? this.getTotal(barang.stocks) : 0;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    async getDataGudang1() {
      await this.$store
        .dispatch("gudang/getData", { jenis: 1 })
        .then(() => {
          let gudang = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gudang.map((item) => {
            item.value = item.id;
            item.text = item.nama_gudang;
          });

          this.id_gudang = gudang;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    setField() {
      if(!this.isSales) {
        this.fields.shift()
        this.fields = [
          { key: "no", label: "No" },
          { key: "sales", label: "Sales" },
          { key: "tgl_pembayaran", label: "Tanggal", sortable: true },
          { key: "metode", label: "Metode", sortable: true },
          { key: "bank", label: "bank", sortable: true },
          { key: "nominal", label: "nominal", sortable: true },
          { key: "keterangan", label: "Keterangan", sortable: true }
        ]
      }
    }
  },
  created() {
    this.setField()
    this.getData();
    this.getDataDashboard();
  }
};
</script>
