var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('view', {
          component: 'ListPengembalian',
          pengembalian: null
        });
      }
    }
  }, [_vm._v("Kembali")]), _c('h3', {
    staticClass: "mt-2 mb-2"
  }, [_vm._v("Detail Pengembalian")]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sales"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.sales ? _vm.item.sales.nama_lengkap : _vm.item.id_sales))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Request Pengembalian"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.humanDate(_vm.item.tanggal)))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.keterangan))])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }